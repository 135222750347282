.screen-shot-loader {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 20px;
  min-height: 250px;
}

.screen-shot img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.screen-shot-title {
  font-family: Archivo;
  margin-top: 0;
  margin-bottom: 1rem;
}
.screen-shot {
  width: 100%;
  height: 70%;
  object-fit: contain;
}

@media (max-width: 600px) {
  .screen-shot-loader {
    margin: 0 auto;
    border-radius: 0;
    padding: 0.75rem;
  }
}
