.tab-card {
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  padding: 1rem 1rem;
}

.tab-card .header-4 {
  text-align: left;
  margin: 0;
}

.tab-button-div {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.tab-add-button {
  position: relative;
  padding: 0;
  color: black;
  background-color: white;
  transition: opacity 0.3s ease-in-out;
  height: 40px;
  width: 40px;
}

.tab-add-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.tab-add-text {
  white-space: nowrap;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  display: none;
  margin-left: 3px;
}

@media (min-width: 1000px) {
  .tab-card:hover .tab-add-button {
    width: 130px;
    border-radius: var(--default-button-border-radius);
    height: var(--large-button-height);
    padding: 0 12px;
    font-size: 18px;
  }

  .tab-card:hover .tab-add-text {
    display: inline;
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 600px) {
  .tab-card {
    height: 10vh;
  }

  .tab-button-div {
    right: 1rem;
    bottom: 1rem;
  }

  .tab-card p {
    font-weight: 400;
  }
}
