body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #fff;
}

:root {
  --brand-orange-1: #fe6533;
  --brown: #3a2b2b;
  --primary-bg-color: #f8f7f2;
  --oval-button-radius: 28px;
  --large-button-height: 40px;
  --default-button-border-radius: 4rem;
  --default-button-height: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Archivo', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.slider-item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5rem;
  height: 65vh;
  border-radius: 1rem;
  margin: 0 0.1rem;
  position: relative;
}

.section-title {
  font-weight: 400;
  text-align: center;
}

.btn {
  border: none;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: var(--default-button-height);
}

.button-large {
  padding: 12px 24px;
}

.btn-rounded {
  border-radius: var(--default-button-border-radius);
}

.btn-large {
  height: 56px;
}

.btn-round {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.card {
  border-radius: 20px !important;
}

.underlined {
  text-decoration: underline !important;
}

section {
  padding: 4rem 3rem;
  overflow: hidden;
}

.img-border {
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}

.header-1 {
  font-family: 'Archivo', sans-serif;
  font-weight: 600;
  font-size: 64px;
}

.header-2 {
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
  font-size: 56px;
  margin: 0;
}

.header-3 {
  font-family: 'Archivo', sans-serif;
  font-weight: 400;
  font-size: 38px;
}

.header-4 {
  font-family: 'Archivo', sans-serif;
  font-weight: 300;
  font-size: 28px;
}

.button-large a,
.button-large span {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.button-small a,
.button-small span {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.button-text {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.body-text-1 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 28px;
}

.body-text-2 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.body-text-3 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.body-text-4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.first-section {
  padding-top: 7rem;
}

@media (max-width: 600px) {
  .btn-round {
    height: 2rem;
    width: 2rem;
  }

  .btn-round img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .card {
    border-radius: 10px !important;
  }

  .img-border {
    border-radius: 8px;
  }

  .button-large a,
  .button-large span {
    font-size: 14px;
  }

  .button-small a,
  .button-small span {
    font-size: 14px;
  }

  .button-text {
    font-size: 14px;
  }

  .header-1 {
    font-size: 42px;
  }
  .header-2 {
    font-size: 32px;
  }
  .header-3 {
    font-size: 28px;
  }
  .header-4 {
    font-size: 20px;
  }

  .body-text-1 {
    font-size: 16px;
  }
  .body-text-2 {
    font-size: 20px;
  }
  .body-text-3 {
    font-size: 16px;
  }
  .body-text-4 {
    font-size: 16px;
  }

  .first-section {
    padding-top: 10rem;
  }

  .button-large {
    padding: 6px 16px;
    height: 32px;
  }

  section {
    padding: 2rem 1.5rem;
  }
}
