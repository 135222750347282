.about-container {
  background-color: #f8f7f2;
  padding-bottom: 1rem !important;
  padding: 2rem 3rem;
}

.footer-logo {
  margin-bottom: 16px;
}

.about-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}

.other-links a {
  color: #616161 !important;
  text-decoration: none !important;
}

.about-section .about-content {
  width: 60%;
}

.about-content {
  color: #616161;
}

.about-content .orange-text {
  color: var(--brand-orange-1);
  font-weight: 600;
}

.about-section .links-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  gap: 10px;
}

.about-section .links-section a {
  color: black;
  text-decoration: underline;
}

.links-section a:hover {
  text-decoration: underline !important;
}

.about-para {
  font-weight: 400;
  color: #7a1600;
}

.link-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.socials {
  display: flex;
  gap: 1rem;
}

.about-heading {
  margin-bottom: 16px;
  font-weight: 500;
}

.crafted-text {
  color: #7a1600;
  font-weight: 600;
  margin-top: 0;
}

.about-divider {
  width: 100%;
  height: 1px;
  margin: 1.2rem 0;
}

.footer-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.copyright-text {
  color: #7a1600;
}

.link-section-child {
  margin-bottom: 20px;
}

/* mobile */
@media (max-width: 600px) {
  .about-section {
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  .about-section .about-content {
    width: 100%;
  }

  .links-section {
    gap: 8px !important;
  }

  .footer-text-container {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .about-container {
    padding: 2rem;
  }
  .link-section-child {
    margin-bottom: 20px;
  }
  .about-heading {
    margin-bottom: 8px;
  }

  .other-links {
    margin-bottom: 0 !important;
  }

  .about-heading {
    font-weight: 600;
  }
}
