.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 56px;
  height: 56px;
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 32px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 2001;
  padding: 40px;
}

.floating-button:hover {
  transform: scale(1.1);
}

.floating-button:active {
  transform: scale(0.95);
}

@media screen and (max-width: 600px) {
  .floating-button {
    bottom: 20px;
    right: 20px;
    padding: 25px;
    font-size: 28px;
  }
}
