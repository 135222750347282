.model-content-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.model-content-image {
  width: 100%;
  height: 70vh;
}

.model-content-scroller {
  width: 100%;
}

.modal-title-div {
  display: flex;
  justify-content: space-between;
  align-items: first baseline;
  width: 100%;
}

@media (max-width: 600px) {
  .model-content-image {
    height: 100%;
  }
}
