.tab-container {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.segment-container {
  display: flex;
  gap: 3px;
  background-color: #f5f5f5;
  padding: 2px;
  border-radius: 12px;
  white-space: nowrap;
}

.segment-container .segment-button {
  border: none;
  padding: 12px 16px;
  border-radius: 10px;
  cursor: pointer;
}

.segment-button span {
  font-weight: 300;
  color: #141414;
}

.active-segment {
  font-weight: 500 !important;
}

.tab-content-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 600px) {
  .tab-container {
    width: 90vw;
  }

  .segment-container {
    width: 90vw;
    overflow: scroll;
  }

  .tab-content-container {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}
