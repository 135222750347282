.highlight-container {
  background: linear-gradient(175deg, #f8f7f2 18%, #fe6533 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
}

.highlight-center {
  width: 100%;
  height: 100%;
}

.section-title-container {
  margin-bottom: 3rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60vh;
  margin: 0 0.1rem;
  position: relative;
}

.highlight-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem;
}

.page-container .section-navigate-icon {
  background-color: white;
}

.page-container .section-navigate-icon a {
  color: black !important;
}

.page-title {
  margin-top: 0;
  text-align: left;
  font-weight: 400;
  margin-bottom: 1rem;
}

.page-image {
  width: 100%;
  align-items: center;
}

.page-container .mobile-image {
  display: none;
}

.section-navigate-button {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  background: #fff;
  border-radius: 28px;
  height: var(--large-button-height);
  padding: 5px 16px !important;
}

.section-navigate-button a {
  color: black;
  font-weight: 500;
}

.digital_platform_section_learn_more_btn a {
  color: #7a1600;
}

.digital_platform_section_learn_more_btn:hover {
  background-color: #7a1600 !important;
}

.digital_platform_section_learn_more_btn:hover a {
  color: #fff !important;
}

.ai_driven_apps_learn_more_btn a {
  color: #7a1600;
}

.ai_driven_apps_learn_more_btn:hover {
  background-color: #7a1600 !important;
}

.ai_driven_apps_learn_more_btn:hover a {
  color: #fff !important;
}

.actionable_insight_learn_more_btn a {
  color: #60007b;
}

.actionable_insight_learn_more_btn:hover {
  background-color: #60007b !important;
}

.actionable_insight_learn_more_btn:hover a {
  color: #fff !important;
}

.collaboration_learn_more_btn a {
  color: #7a1600;
}

.collaboration_learn_more_btn:hover {
  background-color: #7a1600 !important;
}

.collaboration_learn_more_btn:hover a {
  color: #fff !important;
}

.support_learn_more_btn a {
  color: #7a1600;
}

.support_learn_more_btn:hover {
  background-color: #7a1600 !important;
}

.support_learn_more_btn:hover a {
  color: #fff !important;
}

.security_learn_more_btn a {
  color: #60007b;
}

.security_learn_more_btn:hover {
  background-color: #60007b !important;
}

.security_learn_more_btn:hover a {
  color: #fff !important;
}

.pricing_learn_more_btn a {
  color: #7a1600;
}

.pricing_learn_more_btn:hover {
  background-color: #7a1600 !important;
}

.pricing_learn_more_btn:hover a {
  color: #fff !important;
}

@media (max-width: 600px) {
  .page-container {
    height: 75vh;
    width: 90vw;
    overflow: hidden !important;
    margin: 0 auto;
    padding: 0;
  }
  .page-title {
    width: 90%;
  }

  .highlight-card-header {
    padding: 1.5rem;
  }

  .highlight-container {
    padding: 4rem 0;
  }

  .page-container .desktop-image {
    display: none;
  }

  .page-container .btn-round {
    height: 42px !important;
    width: 42px !important;
  }
}
